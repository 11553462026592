/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

( function( $ ) {

	function new_map( $el ) {

		var $markers = $el.find( '.marker' );

		// https://mapstyle.withgoogle.com/ JSON setting

		var styledMapType = new google.maps.StyledMapType(
			[
			  {
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#f5f5f5"
			      }
			    ]
			  },
			  {
			    "elementType": "labels.icon",
			    "stylers": [
			      {
			        "visibility": "off"
			      }
			    ]
			  },
			  {
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#616161"
			      }
			    ]
			  },
			  {
			    "elementType": "labels.text.stroke",
			    "stylers": [
			      {
			        "color": "#f5f5f5"
			      }
			    ]
			  },
			  {
			    "featureType": "administrative.land_parcel",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#bdbdbd"
			      }
			    ]
			  },
			  {
			    "featureType": "poi",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#eeeeee"
			      }
			    ]
			  },
			  {
			    "featureType": "poi",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#757575"
			      }
			    ]
			  },
			  {
			    "featureType": "poi.park",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#e5e5e5"
			      }
			    ]
			  },
			  {
			    "featureType": "poi.park",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#9e9e9e"
			      }
			    ]
			  },
			  {
			    "featureType": "road",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#ffffff"
			      }
			    ]
			  },
			  {
			    "featureType": "road.arterial",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#757575"
			      }
			    ]
			  },
			  {
			    "featureType": "road.highway",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#dadada"
			      }
			    ]
			  },
			  {
			    "featureType": "road.highway",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#616161"
			      }
			    ]
			  },
			  {
			    "featureType": "road.local",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#9e9e9e"
			      }
			    ]
			  },
			  {
			    "featureType": "transit.line",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#e5e5e5"
			      }
			    ]
			  },
			  {
			    "featureType": "transit.station",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#eeeeee"
			      }
			    ]
			  },
			  {
			    "featureType": "water",
			    "elementType": "geometry",
			    "stylers": [
			      {
			        "color": "#c9c9c9"
			      }
			    ]
			  },
			  {
			    "featureType": "water",
			    "elementType": "labels.text.fill",
			    "stylers": [
			      {
			        "color": "#9e9e9e"
			      }
			    ]
			  }
			],
			{name: 'Default'}
		);

		var args = {

			zoom		         : 16,
			center		         : new google.maps.LatLng( 0, 0 ),
			mapTypeControlOptions: {
				mapTypeIds: ['styled_map', 'roadmap', 'satellite', 'hybrid']
			}

		};

		var map = new google.maps.Map( $el[0], args );

		// Associate the styled map with the MapTypeId and set it to display.

		map.mapTypes.set('styled_map', styledMapType);
		map.setMapTypeId('styled_map');

		map.markers = [];

		$markers.each( function() {

			add_marker( $(this), map );

		} );

		center_map( map );

		return map;

	}

	function add_marker( $marker, map ) {

		var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

		var marker = new google.maps.Marker( {

			position : latlng,
			// icon     : argusGoogleMaps.templatedir + '/assets/img/map_marker.png',
			map      : map

		} );

		map.markers.push( marker );

		if ( $marker.html() ) {

			var infowindow = new google.maps.InfoWindow( {

				content: $marker.html()

			} );

			google.maps.event.addListener( marker, 'click', function() {

				if (!marker.open) {

					infowindow.open( map, marker );

					marker.open = true;

				} else {

					infowindow.close();

					marker.open = false;

				}

			} );

		}

	}

	function center_map( map ) {

		var bounds = new google.maps.LatLngBounds();

		$.each( map.markers, function( i, marker ) {

			var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

			bounds.extend( latlng );

		});

		if ( map.markers.length == 1 ) {

			map.setCenter( bounds.getCenter() );
			map.setZoom( 16 );

		} else {

			map.fitBounds( bounds );

		}

	}

	var map = null;

	$(document).ready( function() {

		$('.google-map').each(function() {

			map = new_map( $(this) );

		} );

	} );

} )( jQuery );
